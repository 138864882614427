@import url('https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@font-face {
  //font-family: "Sarabun";
  //src: url("assets/fonts/Sarabun/Sarabun-Regular.ttf");
  //font-family: "IBMPlexSansThaiLooped";
  //src: url("assets/fonts/IBM_Plex_Sans_Thai_Looped/IBMPlexSansThaiLooped-Regular.ttf");
  //font-family: "IBMPlexSansThai";
  //src: url("assets/fonts/IBM_Plex_Sans_Thai/IBMPlexSansThai-Regular.ttf");
  //font-family: "Noto_Sans_Thai";
  //src: url("assets/fonts/Noto_Sans_Thai/static/NotoSansThai-Regular.ttf");
  //font-family: "Noto_Sans_Thai_Looped";
  //src: url("assets/fonts/Noto_Sans_Thai_Looped/NotoSansThaiLooped-Light.ttf");
  //font-weight: normal;
  //font-family: "Prompt";
  //src: url("assets/fonts/Prompt/Prompt-Regular.ttf");
}

@font-face {
  font-family: "Kanit";
  src: url("assets/fonts/Kanit/Kanit-Light.ttf");
}

@font-face {
  font-family: "Noto_Sans_Thai_Looped";
  src: url("assets/fonts/Noto_Sans_Thai_Looped/NotoSansThaiLooped-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Noto_Sans_Thai_Looped";
  src: url("assets/fonts/Noto_Sans_Thai_Looped/NotoSansThaiLooped-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Noto_Sans_Thai_Looped";
  src: url("assets/fonts/Noto_Sans_Thai_Looped/NotoSansThaiLooped-Bold.ttf");
  font-weight: 800;
}

* {
  font-family: K2D, sans-serif;
  font-size: 14px;
}

html, body {
  //background-color: #F5F5F5FF;
  background-color: #f9f9f9;
}

html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
  overflow-y: hidden;
  background: #f4f5fa;
  background-size: cover;
}

body {
  overflow-y: hidden;
}

body[data-default-font-size='14px'] #content * {
  font-size: 14px;
}

body[data-default-font-size='16px'] #content * {
  font-size: 16px;
}

body[data-default-font-size='18px'] #content * {
  font-size: 18px;
}

.table {
  font-size: 0.825rem;

  th {
    font-weight: 500;
  }
}

label {
}

.text-hint {
  color: #a4b2c0bf;
  cursor: pointer;
}

.popover-header {
  background-color: #f8fafc;
  border: 0;
}

.cursor-pointer {
  cursor: pointer;
}

//.card {
//  //box-shadow: 0 0 #0000, 0 0 #0000, 0 0.625rem 1.875rem rgba(0, 0, 0, .03);
//  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
//}

.custom-box {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 2.5rem;
}

.custom-card {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 1rem;
}

.bd-callout {
  --bs-link-color-rgb: var(--bd-callout-link);
  --bs-code-color: var(--bd-callout-code-color);
  padding: 1.25rem;
  //margin-top: 1.25rem;
  //margin-bottom: 1.25rem;
  color: var(--bd-callout-color, inherit);
  background-color: var(--bd-callout-bg, var(--bs-gray-100));
  border-left: 0.25rem solid var(--bd-callout-border, var(--bs-gray-300));
}

.bd-callout-warning {
  --bd-callout-color: var(--bs-warning-text-emphasis);
  --bd-callout-bg: var(--bs-warning-bg-subtle);
  --bd-callout-border: var(--bs-warning-border-subtle);
}

.custom-card {
  height: 100%;

  .card-button {
    font-weight: bold;
    opacity: .75;
  }
}

.text-custom-theme {
  color: #017846;
}

/* Component when overflow*/
app-register, app-register, app-login, app-qr-code {
  display: block;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.my-drop-zone {
  border: dashed 2px lightgray;
  padding: 20px;
  height: 20rem;
  border-radius: 15px;
  text-align: center;
}

.nv-file-over {
  border: dashed 2px #8EBA65;
  padding: 20px;
  border-radius: 15px;
}

.set-width-modal .modal-dialog {
  max-width: calc(100vw - 700px);
}

.accordion {
 // box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.accordion-button:not(.collapsed){
 /* background-color: #017846;*/
  background-color: #009f6f;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-radius: 1rem;
}

.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.accordion-item:first-of-type {
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}


.accordion-button:not(.collapsed)::after {
  display: none;
}

.accordion-button::after {
  display: none;
}

.bg-white {
  --bs-bg-opacity: 0;
}

.custom-head-title {
  background-color: #e3f0ff;
}

.custom-head-title-green {
  background-color: #e3ffe4;
}

.fs-5-5{
  font-size:1.06rem!important
}
