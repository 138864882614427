.btn-custom {
  min-width: 120px;
  border-radius: 50rem !important;
  font-size: 1.10rem;
}

.btn-dotted {
  //background: unset;
  //border: 2px dotted #AABDF4;
  //color: #AABDF4;
}

.btn-outline-warning {
  border-color: #F49A41;
  color: #F49A41;
}

.btn-outline-warning:hover, .btn-outline-warning.active {
  background: darken(#F49A41, 10) !important;
  border-color: darken(#F49A41, 10) !important;
  outline: none !important;
  box-shadow: none !important;
  color: white;
}

button[aria-label="Close"]:focus {
  outline: none;
}

.btn-default {
  border-bottom: 1px solid #dee2e6 !important;
}

.btn-custom-theme {
  background-color: #009f6f;
  color: white;
}

.btn-custom-theme:hover {
  background-color: #ddeaee;
  color: #009f6f;
}

.btn-light-custom {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-light {
  background-color: #e4e4e4;
}

.btn-light:hover {
  background-color: #8f8f8f;
  color: white;
}
