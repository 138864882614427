table {
  thead {
    tr {
      //border-bottom-color: rgb(236 236 236) !important;
      //border-bottom: 1px solid rgb(236 236 236) !important;
      //box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
    th {
      font-weight: normal;
      vertical-align: middle;
      color: rgb(33 37 41 / 75%) !important;
      background-color: #f8f8f8 !important;
      //border-bottom-color: rgb(120 120 120) !important;

    }
  }

  .col-date {
    text-align: center;
    font-size: 0.88rem;
  }

  //.btn {
  //  font-size: 0.8rem;
  //}
}
