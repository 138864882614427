span.badge.rounded-pill {
  padding-top: 0.4rem;
}

ngb-pagination .page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

ngb-pagination .page-item .page-link {
  border: 0;
  border-radius: 5px;
  color: #017846;
  //width: 34px;
  //height: 34px;
  //margin-left: 0.5rem;
  //margin-right: 0.5rem;
}

ngb-pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  //background-color: #2592e0;
  //border-color: #2592e0;
}

ngb-pagination .page-item.disabled .page-link {
  background-color: #eeeeee;
}

.page-link.active, .active > .page-link {
  background-color: #017846;
}
